import { motion } from 'framer-motion';
import { orderBy } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { AutoSizer, List, MultiGrid } from 'react-virtualized';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import {
  addElementsAdminApproval,
  addElementsAdminStatusReset,
  addElementsStatusReset,
  completeElements,
  createUserFilter,
  deleteUserFilter,
  getFilters,
  getLimitedPersonsElementStatus,
  getOrganisationUnitsElementStatus,
  getPersonsElementStatus,
  updateElementsMediator,
  updateElementsStatus,
  updateUserFilter,
} from '../../actions/statusTableActions';
import Aside from '../../components/Aside';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import DateTime from '../../components/DateTime';
import ElementHistoryList from '../../components/ElementHistoryList';
import ErrorMessage from '../../components/formElements/ErrorMessage';
import FormWrapper from '../../components/formElements/FormWrapper';
import Input from '../../components/formElements/Input';
import WithModals from '../../components/HOC/withModals';
import withPersonLookup from '../../components/HOC/withPersonLookup';
import Icon from '../../components/Icon';
import LoadOverlay from '../../components/LoadOverlay';
import LoadScreen from '../../components/LoadScreen';
import Modal from '../../components/Modal';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import GenericModal from '../../components/modal/GenericModal';
import InfoModal from '../../components/modal/InfoModal';
import RichTextModal from '../../components/modal/RichTextModal';
import NotificationCounter from '../../components/NotificationCounter';
import OrganisationUnitsText from '../../components/OrganisationUnitsText';
import Portal from '../../components/Portal';
import SideNavFilter from '../../components/sideNav/SideNavFilter';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavListSection from '../../components/sideNav/SideNavListSection';
import SideNavText from '../../components/sideNav/SideNavText';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SideNavVirtualListWrapper from '../../components/sideNav/SideNavVirtualListWrapper';
import SlideButton from '../../components/SlideButton';
import StatusButton from '../../components/StatusButton';
import StatusTableHeader from '../../components/StatusTableHeader';
import TableCell from '../../components/TableCell';
import TableHeaderCell from '../../components/TableHeaderCell';
import localeLookup from '../../config/locale';
import {
  CHAMP_ID,
  DELETED_GROUP_ID,
  DELETED_PERSON_ID,
  EMPTY_ID,
  EVENT_NAMES,
  EXPERT_ID,
  KNOWLEDGE_OWNER_ID,
  LOCK_STATES,
  MAIN_STATUS_CATEGORIES,
  MENTOR_ID,
  NONE_ID,
  PENDING_STATES,
  SELF_STUDY_ID,
  STATUS_CATEGORIES,
  TRAINING_REGISTRATION_TYPES,
} from '../../constants';
import { getElementContentIcon } from '../../utils/elementHelpers';
import { compareLocal, formatDate, sortBy } from '../../utils/helpers';
import { trackEvent } from '../../utils/tracking';
import ElementStatusElementConfigurationFilter from './ElementStatusElementConfigurationFilter';
import ElementStatusOtherFiltersFilter from './ElementStatusOtherFiltersFilter';
import ElementStatusStatusFilter from './ElementStatusStatusFilter';
import ElementStatusOrganisationUnitFilter from './ElementStatusOrganisationUnitFilter';
import EmptyState from '../../components/EmptyState';
import { checkForLargeDataSetService } from '../../services/statusTableService';
import ButtonIcon from '../../components/ButtonIcon';

const mapStateToProps = (state) => {
  const { statusTable } = state;
  return {
    organisationUnits: statusTable.organisationUnits,
    roles: statusTable.roles,
    knowledgeAreas: statusTable.knowledgeAreas,
    knowledgeElements: statusTable.knowledgeElements,
    persons: statusTable.persons,
    wildcardPersons: statusTable.wildcardPersons,
    categories: statusTable.categories,
    presetFilters: statusTable.presetFilters,
    hasLoadedStatus: statusTable.hasLoadedStatus,
    organisationUnitRootNodes: statusTable.organisationUnitRootNodes,
    groups: state.groups,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createUserFilter,
      deleteUserFilter,
      getFilters,
      getLimitedPersonsElementStatus,
      getPersonsElementStatus,
      updateElementsMediator,
      updateElementsStatus,
      updateUserFilter,
      addElementsAdminApproval,
      addElementsStatusReset,
      completeElements,
      addElementsAdminStatusReset,
      getOrganisationUnitsElementStatus,
    },
    dispatch
  );

const AnimatedAside = motion(Aside);

const submenuVariants = {
  hidden: {
    x: '-100%',
    transition: {
      ease: 'easeIn',
    },
  },
  visible: {
    x: 0,
    transition: {
      ease: 'easeIn',
    },
  },
};

const COLUMN_IDS = {
  ELEMENT_NAME: 'elementName',
  PERSON_NAME: 'personName',
  RESPONSIBLE: 'responsible',
  MENTOR: 'mentor',
  LATEST_ACTIVITY: 'latestActivity',
  EXPIRATION_DATE: 'expirationDate',
  ORGANISATION_UNIT: 'organisationUnit',
  ROLE: 'role',
  MODULE: 'module',
};

const statusFilterDefaultState = {
  noCompletion: true,
  validCompletion: true,
  validCompletionExpiring: true,
  invalidCompletionExpired: true,
  invalidCompletionOngoing: true,
};

const elementConfigurationDefaultState = {
  critical: true,
  notCritical: true,
  requiresSignature: true,
  doesNotRequireSignature: true,
  multipart: true,
  notMultipart: true,
  withExpiration: true,
  withoutExpiration: true,
};

const otherFiltersDefaultState = {
  activePersons: true,
  archivedPersons: false,
  currentlyConnected: true,
  notConnected: false,
};

class ElementStatus extends PureComponent {
  constructor() {
    super();
    this.state = {
      modal: {
        activeModal: '',
        title: '',
      },
      activeSubMenuProp: null,
      filters: {
        status: statusFilterDefaultState,
        elementConfiguration: elementConfigurationDefaultState,
        otherFilters: otherFiltersDefaultState,
        organisationUnits: {
          deselected: [],
        },
        roles: {
          deselected: [],
        },
        knowledgeAreas: {
          deselected: [],
        },
        knowledgeElements: {
          deselected: [],
        },
        persons: {
          deselected: [],
        },
        trainers: {
          deselected: [],
        },
        mentors: {
          deselected: [],
        },
      },
      filterSearchQuery: '',
      isLoading: false,
      isLoadingInitial: true,
      rows: [],
      selectedRows: {},
      showSubMenu: false,
      sorting: { columnId: COLUMN_IDS.ELEMENT_NAME, direction: 'asc' },
      subMenuTitle: '',
    };
    this.headerCells = [
      {
        type: 'checkbox',
      },
      {
        type: 'regular',
        title: localeLookup('translations.Element'),
        sortable: true,
        columnId: COLUMN_IDS.ELEMENT_NAME,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Person'),
        sortable: true,
        columnId: COLUMN_IDS.PERSON_NAME,
      },
      {
        type: 'status',
      },
      {
        type: 'regular',
        title: localeLookup('translations.Responsible'),
        sortable: true,
        columnId: COLUMN_IDS.RESPONSIBLE,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Mentor'),
        sortable: true,
        columnId: COLUMN_IDS.MENTOR,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Latest activity'),
        sortable: true,
        columnId: COLUMN_IDS.LATEST_ACTIVITY,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Expiration date'),
        sortable: true,
        columnId: COLUMN_IDS.EXPIRATION_DATE,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Organisation unit'),
        sortable: true,
        columnId: COLUMN_IDS.ORGANISATION_UNIT,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Role'),
        sortable: true,
        columnId: COLUMN_IDS.ROLE,
      },
      {
        type: 'regular',
        title: localeLookup('translations.Knowledge area'),
        sortable: true,
        columnId: COLUMN_IDS.MODULE,
      },
    ];
    this.filterPresetActions = [
      {
        title: localeLookup('translations.Update'),
        icon: 'register',
        onClick: (filter) => this.onUserFilterUpdateClick(filter),
      },
      {
        title: localeLookup('translations.Rename'),
        icon: 'pencil',
        onClick: (filter) => this.onUserFilterRenameClick(filter),
      },
      {
        title: localeLookup('translations.Delete'),
        icon: 'trash2',
        color: 'red',
        onClick: (filter) => this.onUserFilterDeleteClick(filter),
      },
    ];
  }

  componentDidMount() {
    const { getFilters, history } = this.props;
    trackEvent('$pageview');
    getFilters().then((data) => {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            organisationUnits: {
              deselected: Object.keys(data.organisationUnits),
            },
          },
        },
        () => {
          // Used for "Open in element status"
          const stateFilter = history?.location?.state?.filterSettings;
          if (stateFilter) {
            this.onUserFilterLoadClick(stateFilter);
          }
        }
      );
      this.buildRows();
    });
  }

  buildRow = ({ elementId, personId, personElementRoleConnections }) => {
    const {
      knowledgeElements,
      knowledgeAreas,
      persons,
      organisationUnits,
      roles,
      showModal,
      lookupPerson,
    } = this.props;
    const element = knowledgeElements[elementId];
    if (!element) return;
    const person = persons[personId];
    const connectionRoleNames = personElementRoleConnections.map(
      (id) => roles[id].name
    );
    const mentorId = this.getPersonElementMentorId({ elementId, personId });
    const mentorObject = lookupPerson(mentorId);
    const getMentorTooltip = () => {
      if (mentorObject && mentorObject.isGroup) {
        return mentorObject.tooltip;
      } else {
        return (
          mentorObject &&
          `${mentorObject.initials}${
            mentorObject.employeeNumber
              ? ` · ${mentorObject.employeeNumber}`
              : ''
          }`
        );
      }
    };
    const organisationUnitNames = sortBy(
      this.getElementOrganisationUnits({ personId, elementId }).map(
        (id) => organisationUnits[id]?.name
      ),
      [(a, b) => compareLocal(a, b)],
      ['asc']
    ).join(', ');

    // eslint-disable-next-line consistent-return
    return {
      0: {
        type: 'checkbox',
        elementId: element.id,
        personId,
      },
      1: {
        columnId: COLUMN_IDS.ELEMENT_NAME,
        type: 'regular',
        title: element.name,
        onIconClick: () =>
          this.onShowDescriptionClick({
            name: element.name,
            description: element.description,
            files: element.files,
          }),
        icon: getElementContentIcon({
          description: element.description,
          files: element.files,
        }),
      },
      2: {
        columnId: COLUMN_IDS.PERSON_NAME,
        type: 'regular',
        title: person.name,
        tooltip: `${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        }`,
        subtitle: person.isActive
          ? null
          : localeLookup('translations.Archived'),
      },
      3: {
        type: 'status',
        elementId: element.id,
        areaId: element.connections.knowledgeArea,
        personId,
        elementName: element.name,
        personName: person.name,
      },
      4: {
        columnId: COLUMN_IDS.RESPONSIBLE,
        type: 'dynamic',
        getTitle: () =>
          this.getTrainerInfo({ personId, elementId: element.id }).name,
        getTitleColor: () => {
          if (
            this.isPersonElementMissingResponsible({
              elementId,
              personId,
              areaId: element.connections.knowledgeArea,
            })
          ) {
            return 'red';
          }
          return '';
        },
        getSubtitle: () =>
          this.getTrainerInfo({ personId, elementId: element.id }).subtitle,
        getSubtitleTooltip: () =>
          this.getTrainerInfo({ personId, elementId: element.id })
            .subtitleTooltip,
        getTooltip: () =>
          this.getMediatorTooltip({
            personId,
            elementId: element.id,
          }),
      },
      5: {
        columnId: COLUMN_IDS.MENTOR,
        type: 'regular',
        title: mentorObject && `${mentorObject?.name} ${mentorObject?.suffix}`,
        tooltip: getMentorTooltip(),
        titleColor: mentorObject?.showError ? 'red' : 'black',
      },
      6: {
        columnId: COLUMN_IDS.LATEST_ACTIVITY,
        type: 'dynamic',
        sortProperty:
          this.getPersonElementLatestActivity({
            personId,
            elementId: element.id,
          }).date !== '',
        sortProperty2: this.getPersonElementLatestActivity({
          personId,
          elementId: element.id,
        }).date,
        getIcon: () =>
          this.hasElementHistory({ personId, elementId: element.id })
            ? 'calendar-check'
            : null,
        onIconClick: () =>
          showModal('elementHistory', {
            elementId: element.id,
            subtitle: `${person.name} · ${element.name}`,
            personId,
            fullWidth: true,
            maxWidth: '500px',
            cancelButtonText: localeLookup('translations.Close'),
            title: localeLookup('translations.History'),
            onChangeHistory: () => this.updatePerson(personId),
          }),
        getTitle: () =>
          this.getPersonElementLatestActivity({
            personId,
            elementId: element.id,
          }).type,
        getSubtitleTooltip: () =>
          this.getPersonElementLatestActivity({
            personId,
            elementId: element.id,
          }).tooltip,
        getSubtitle: () => {
          const latestActivity = this.getPersonElementLatestActivity({
            personId,
            elementId: element.id,
          });
          if (latestActivity?.signedBy?.length > 0) {
            return (
              <>
                {latestActivity.signedBy}, {latestActivity.formattedDate}
              </>
            );
          }
          return '';
        },
      },
      7: {
        columnId: COLUMN_IDS.EXPIRATION_DATE,
        type: 'dynamic',
        sortProperty:
          this.getPersonElementExpirationDate({
            personId,
            elementId: element.id,
          }).date !== '',
        sortProperty2: this.getPersonElementExpirationDate({
          personId,
          elementId: element.id,
        }).date,
        getTitle: () => {
          const expirationDate = this.getPersonElementExpirationDate({
            personId,
            elementId: element.id,
          });
          return <>{expirationDate.formattedDate}</>;
        },
      },
      8: {
        columnId: COLUMN_IDS.ORGANISATION_UNIT,
        type: 'regular',
        title: organisationUnitNames,
      },
      9: {
        columnId: COLUMN_IDS.ROLE,
        type: 'regular',
        title: connectionRoleNames.sort().join(', '),
      },
      10: {
        columnId: COLUMN_IDS.MODULE,
        type: 'regular',
        title: knowledgeAreas[element.connections.knowledgeArea].name,
      },
      data: {
        elementId: element.id,
        personId,
        areaId: element.connections.knowledgeArea,
      },
    };
  };

  buildRowsForPerson = (personId) => {
    const { filters } = this.state;
    const { persons } = this.props;
    const person = persons[personId];
    if (!person.knowledgeElements) return [];
    return Object.keys(person.knowledgeElements).reduce((acc, elementId) => {
      const showPerson =
        (filters.otherFilters.archivedPersons && !person.isActive) ||
        (filters.otherFilters.activePersons && person.isActive);
      if (showPerson) {
        const personElement = person.knowledgeElements[elementId];
        const isElementVisible = this.isElementVisible({
          personElementRoleConnections: personElement.connectedRoles,
          elementId,
          personId,
          status: personElement.status,
          pending: personElement.pending,
          hasValidity: personElement.hasValidity,
        });

        if (isElementVisible) {
          const row = this.buildRow({
            elementId,
            personId,
            isCompleted: personElement.isCompleted,
            mediator: personElement.mediator,
            completedAt: personElement.completedAt,
            personElementRoleConnections: personElement.connectedRoles,
          });
          if (row) return [...acc, row];
        }
      }
      return acc;
    }, []);
  };

  buildRows = () => {
    const { filters } = this.state;
    const { persons } = this.props;
    const rows = Object.keys(persons).reduce((acc, personId) => {
      const person = persons[personId];
      const isAllPersonsOrganisationUnitsDeselected =
        filters.organisationUnits.deselected.length > 0 &&
        person.organisationUnits.every((unitId) =>
          filters.organisationUnits.deselected.includes(unitId)
        );
      const isPersonDeselected = filters.persons.deselected.includes(personId);

      if (isAllPersonsOrganisationUnitsDeselected || isPersonDeselected) {
        return acc;
      }
      return [...acc, ...this.buildRowsForPerson(personId)];
    }, []);
    this.setState({
      rows: this.getSortedRows(rows),
      isLoadingInitial: false,
    });
  };

  addAdminApprovalToSelectedElements = ({ note, validFromDate }) => {
    const { selectedRows } = this.state;
    const { addElementsAdminApproval } = this.props;
    this.setState({ isLoading: true });
    return addElementsAdminApproval({
      personElements: selectedRows,
      note,
      validFromDate,
    }).then(() => {
      this.setState({ isLoading: false });
      this.deselectAllRows();
    });
  };

  addAdminStatusResetToSelectedElements = ({ note, validFromDate }) => {
    const { selectedRows } = this.state;
    const { addElementsAdminStatusReset } = this.props;
    this.setState({ isLoading: true });
    return addElementsAdminStatusReset({
      personElements: selectedRows,
      note,
      validFromDate,
    }).then(() => {
      this.setState({ isLoading: false });
      this.deselectAllRows();
    });
  };

  addStatusResetToSelectedElements = ({ note }) => {
    const { selectedRows } = this.state;
    const { addElementsStatusReset } = this.props;
    this.setState({ isLoading: true });
    return addElementsStatusReset({ personElements: selectedRows, note }).then(
      () => {
        this.setState({ isLoading: false });
        this.deselectAllRows();
      }
    );
  };

  createUserFilter = (name) => {
    const { createUserFilter } = this.props;
    createUserFilter({ ...this.getUserFilterObject(), name });
  };

  changeSelectedElementsMediator = (mediator) => {
    const { selectedRows } = this.state;
    const { updateElementsMediator, knowledgeElements } = this.props;
    this.setState({ isLoading: true });
    return updateElementsMediator({
      personElements: selectedRows,
      allKnowledgeElements: knowledgeElements,
      mediator,
    }).then(() => {
      this.setState({ isLoading: false });
      this.deselectAllRows();
    });
  };

  checkForLargeDataAmount = async ({ onConfirm, organisationUnits }) => {
    try {
      await checkForLargeDataSetService(organisationUnits);
      onConfirm();
    } catch (error) {
      const { response } = error;
      this.showModal({
        modalType: 'confirmation',
        modalProps: {
          title: localeLookup('translations.Large data amount'),
          body: localeLookup(
            'translations.The chosen organisation units contains a large data amount. This may take a while to load. Do you want to continue?'
          ),
          confirmButtonText: localeLookup('translations.Continue'),
          onConfirm: onConfirm,
          fullWidth: true,
          maxWidth: '500px',
        },
      });
    }
  };

  completeSelectedElements = () => {
    const { selectedRows } = this.state;
    const { completeElements } = this.props;
    this.setState({ isLoading: true });
    return completeElements({ personElements: selectedRows }).then(() => {
      this.setState({ isLoading: false });
      this.deselectAllRows();
    });
  };

  deselectAllRows = () => {
    this.setState({
      selectedRows: {},
    });
  };

  getPersonElementExpirationDate = ({ elementId, personId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personElement = person?.knowledgeElements[elementId];
    if (personElement.expirationDate !== '0001-01-01T00:00:00') {
      return {
        date: personElement.expirationDate,
        formattedDate: (
          <DateTime date={personElement.expirationDate}></DateTime>
        ),
      };
    } else {
      return { date: '', formatDate: '' };
    }
  };
  getPersonElementLatestActivity = ({ elementId, personId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personElement = person?.knowledgeElements[elementId];
    const latestActivity = personElement?.latestActivity;
    const signee = persons[latestActivity.signedBy];
    if (latestActivity.signedBy === DELETED_PERSON_ID) {
      return {
        type: localeLookup(EVENT_NAMES[latestActivity.type]),
        signedBy: localeLookup('translations.Deleted person'),

        date: new Date(latestActivity.signedAt),
        formattedDate: (
          <DateTime includeTime date={latestActivity.signedAt}></DateTime>
        ),
      };
    }
    if (latestActivity.signedBy !== EMPTY_ID && signee) {
      return {
        type: localeLookup(EVENT_NAMES[latestActivity.type]),
        signedBy:
          latestActivity.signedBy === DELETED_PERSON_ID
            ? localeLookup('translations.Deleted person')
            : signee?.name,
        tooltip: `${signee.initials}${
          signee.employeeNumber ? ` · ${signee.employeeNumber}` : ''
        }`,
        date: new Date(latestActivity.signedAt),
        formattedDate: (
          <DateTime includeTime date={latestActivity.signedAt}></DateTime>
        ),
      };
    }
    return {
      type: '',
      signedBy: '',
      date: '',
      tooltip: '',
      formattedDate: '',
    };
  };

  getElementOrganisationUnits = ({ elementId, personId }) => {
    const { persons, roles } = this.props;
    const person = persons[personId];
    const personElement = person.knowledgeElements[elementId];
    const personElementOrganisationUnits = personElement.connectedRoles.reduce(
      (acc, roleId) => {
        const role = roles[roleId];
        const personRole = person.roles[roleId];
        const personRoleOrganisationUnits = role.organisationUnits.reduce(
          (acc, unitId) => {
            if (
              personRole.notRelevantInOrganisationUnits.includes(unitId) ||
              !person.organisationUnits.includes(unitId)
            ) {
              return acc;
            }
            return [...acc, unitId];
          },
          []
        );
        return [...acc, ...personRoleOrganisationUnits];
      },
      []
    );
    return [...new Set(personElementOrganisationUnits)];
  };

  getElementStatusInfo = ({ elementId, personId }) => {
    const { persons } = this.props;
    if (persons[personId]) {
      const personElement = persons[personId].knowledgeElements[elementId];
      return {
        isLocked: personElement.isLocked,
        isCompleted: personElement.isCompleted,
        hasExpiration: personElement.hasExpiration,
        daysUntilExpiration: personElement.daysUntilExpiration,
        status: personElement.status,
        nextAction: personElement.nextAction,
        pending: personElement.pending,
        hasValidity: personElement.hasValidity,
        mediatorId: personElement.mediator,
        hasHistory: personElement.hasHistory,
      };
    }
    return {};
  };

  getFilteredSubMenuItems = () => {
    const { filterSearchQuery } = this.state;
    return this.getSubMenuItems().filter((item) => {
      if (item.searchString) {
        return item.searchString
          .toLowerCase()
          .includes(filterSearchQuery.toLowerCase());
      }
      return (
        item.name?.toLowerCase().includes(filterSearchQuery.toLowerCase()) ||
        item.subtitle?.toLowerCase().includes(filterSearchQuery.toLowerCase())
      );
    });
  };

  getFilterSelectionCount = (propKey, stateKey) => {
    const { filters } = this.state;
    const { groups } = this.props;
    const items = this.props[propKey];
    if (!items) return 0;
    const deselectedCount = filters[stateKey].deselected.length;
    let totalCount = Object.keys(items).length;
    // Trainers has 5 extra items from wildcardpersons and no mediator option
    if (stateKey === 'trainers') totalCount += 5;
    if (stateKey === 'mentors') {
      totalCount += 4 + this.getMentorGroupFilterValues().length;
    }
    if (deselectedCount === 0) {
      if (stateKey === 'organisationUnits') {
        return totalCount - deselectedCount;
      }
      return null;
    }
    return totalCount - deselectedCount;
  };

  getMediatorName = (personId, elementId) => {
    const { persons, wildcardPersons } = this.props;
    const person = persons[personId];
    const personElement = person?.knowledgeElements[elementId];
    const id = personElement?.mediator;
    if (id === SELF_STUDY_ID) return person.name;
    if (id === EMPTY_ID && personElement?.lockState === LOCK_STATES.LOCKED)
      return localeLookup('translations.Relevant person');
    if (wildcardPersons[id]) return wildcardPersons[id].name;
    if (persons[id]) {
      if (persons[id].isActive) {
        return persons[id].name;
      }
      return `${persons[id].name} (${localeLookup('translations.Archived')})`;
    }
    return '';
  };

  getMediatorTooltip = ({ personId, elementId }) => {
    const { knowledgeAreas, persons, knowledgeElements, lookupPerson } =
      this.props;
    const mediatorId = persons[personId].knowledgeElements[elementId]?.mediator;
    const mediatorObject = lookupPerson(mediatorId);

    const knowledgeAreaId =
      knowledgeElements[elementId].connections.knowledgeArea;
    if (mediatorId === EXPERT_ID) {
      if (knowledgeAreas[knowledgeAreaId].experts.length === 0) {
        return localeLookup('translations.No experts');
      }
      return knowledgeAreas[knowledgeAreaId].experts
        .reduce((acc, id) => {
          if (persons[id]) {
            return [...acc, persons[id].name];
          }
          return acc;
        }, [])
        .join(', ');
    }
    if (mediatorId === KNOWLEDGE_OWNER_ID) {
      return localeLookup('translations.No knowledge owner');
    }
    if (mediatorObject && !mediatorObject.isWildcardPerson) {
      return `${mediatorObject.initials}${
        mediatorObject.employeeNumber
          ? ` · ${mediatorObject.employeeNumber}`
          : ''
      }`;
    }
    return null;
  };

  getOrganisationUnitsData = (ids) => {
    const {
      organisationUnits,
      getOrganisationUnitsElementStatus,
      showModal,
      hideModal,
    } = this.props;
    const { filters } = this.state;
    const { organisationUnits: stateOrganisationUnits } = filters;
    this.setState({ isLoading: true });
    const selectedUnitIds = Object.keys(organisationUnits).filter(
      (id) => !stateOrganisationUnits.deselected.includes(id)
    );
    return getOrganisationUnitsElementStatus(ids || selectedUnitIds)
      .then(() => this.setState({ isLoading: false }))
      .catch((e) => {
        if (e.response.status === 504) {
          this.setState({ isLoading: false });
          showModal('infoModal', {
            title: localeLookup('translations.Error'),
            maxWidth: '500px',
            body: localeLookup(
              'translations.The request took too long to process. Please try again or contact Champ if the issue persists.'
            ),
            onConfirm: () => {
              hideModal();
            },
          });
        }
      });
  };

  getPersonElementMentorId = ({ elementId, personId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personElement = person.knowledgeElements[elementId];
    // We loop over the element's connected roles, to find the first id of a role with a mentor
    const roleWithMentorId = personElement.connectedRoles.find(
      (roleId) =>
        person.roles[roleId] && person.roles[roleId].mentorId !== EMPTY_ID
    );
    // If theres a role with a mentor we return the id of the mentor
    if (roleWithMentorId) return person.roles[roleWithMentorId].mentorId;
    return EMPTY_ID;
  };

  getSelectedRowsCount = () => {
    const { selectedRows } = this.state;
    return Object.keys(selectedRows).reduce(
      (acc, personId) => selectedRows[personId].length + acc,
      0
    );
  };

  getSortedRows = (rows) => {
    const { sorting } = this.state;
    let sortedRows;
    if (
      sorting.columnId === COLUMN_IDS.EXPIRATION_DATE ||
      sorting.columnId === COLUMN_IDS.LATEST_ACTIVITY
    ) {
      sortedRows = orderBy(
        rows,
        [
          (row) => {
            const rowCell = Object.values(row).find(
              (cell) => cell.columnId === sorting.columnId
            );
            if (rowCell.sortProperty !== undefined) {
              return !rowCell.sortProperty;
            }
          },
          (row) => {
            const rowCell = Object.values(row).find(
              (cell) => cell.columnId === sorting.columnId
            );
            if (rowCell.sortProperty2 !== undefined) {
              return rowCell.sortProperty2;
            }
          },
        ],
        ['asc', sorting.direction]
      );
    } else {
      sortedRows = sortBy(
        rows,
        [
          (a, b) => {
            const rowCellA = Object.values(a).find(
              (cell) => cell.columnId === sorting.columnId
            );
            const rowCellB = Object.values(b).find(
              (cell) => cell.columnId === sorting.columnId
            );
            if (rowCellA.sortProperty !== undefined) {
              return compareLocal(rowCellA.sortProperty, rowCellB.sortProperty);
            }
            if (rowCellA.type === 'dynamic') {
              const titleA = rowCellA.getTitle() || '';
              const titleB = rowCellB.getTitle() || '';
              return compareLocal(titleA, titleB);
            }
            return compareLocal(rowCellA?.title || '', rowCellB?.title || '');
          },
        ],
        [sorting.direction]
      );
      /* sortedRows = orderBy(
        rows,
        (row) => {
          const rowCell = Object.values(row).find(
            (cell) => cell.columnId === sorting.columnId
          );
          if (rowCell.sortProperty !== undefined) {
            return rowCell.sortProperty;
          }
          if (rowCell.type === 'dynamic') {
            const title = rowCell.getTitle();
            if (title) {
              return rowCell.getTitle().toLowerCase();
            }
            return '';
          }
          return rowCell?.title?.toLowerCase() || '';
        },
        [sorting.direction]
      ); */
    }

    return sortedRows;
  };

  getSubMenuItems = () => {
    const { categories, organisationUnits, knowledgeAreas } = this.props;
    const { activeSubMenuStateFilterKey, activeSubMenuProp, filters } =
      this.state;
    const items = this.props[activeSubMenuProp];
    if (!activeSubMenuProp || !items) {
      return [];
    }

    const getSubtitle = (item, type) => {
      if (type === 'knowledgeAreas') {
        return categories[item.category] && categories[item.category].name;
      }
      if (type === 'roles') {
        return (
          <OrganisationUnitsText
            organisationUnits={item.organisationUnits
              .filter((id) => organisationUnits[id])
              .map((id) => {
                return organisationUnits[id].name;
              })}
          />
        );
      }
      if (type === 'knowledgeElements') {
        return (
          knowledgeAreas[item.connections.knowledgeArea] &&
          knowledgeAreas[item.connections.knowledgeArea].name
        );
      }
      if (type === 'persons' || type === 'trainers' || type === 'mentors') {
        return item.isActive === false
          ? localeLookup('translations.Archived')
          : '';
      }
      return null;
    };

    const getSearchString = (item, type) => {
      if (type === 'persons' || type === 'trainers' || type === 'mentors') {
        return `${item.name}${item.initials}${item.employeeNumber}`;
      }
      if (type === 'roles') {
        return `${item.name}${item.organisationUnits
          .filter((id) => organisationUnits[id])
          .map((id) => organisationUnits[id].name)}`.toLowerCase();
      }
      return null;
    };

    const getTooltip = (item, type) => {
      if (type === 'persons' || type === 'trainers' || type === 'mentors') {
        return `${item.initials}${
          item.employeeNumber ? ` · ${item.employeeNumber}` : ''
        }`;
      }
      return null;
    };

    const subMenuItems = Object.keys(items).map((id) => ({
      selected: !filters[activeSubMenuStateFilterKey].deselected.includes(id),
      name: items[id].name,
      subtitle: getSubtitle(items[id], activeSubMenuStateFilterKey),
      searchString: getSearchString(items[id], activeSubMenuStateFilterKey),
      tooltip: getTooltip(items[id], activeSubMenuStateFilterKey),
      id,
      onToggle: () =>
        this.onToggleFilter({ type: activeSubMenuStateFilterKey, id }),
    }));

    if (activeSubMenuStateFilterKey === 'trainers') {
      return [
        ...this.getTrainerDefaultFilterValues(),
        ...sortBy(
          subMenuItems,
          [(a, b) => compareLocal(a.name, b.name)],
          ['asc']
        ),
      ];
    }
    if (activeSubMenuStateFilterKey === 'mentors') {
      return [
        ...this.getMentorDefaultFilterValues(),
        ...this.getMentorGroupFilterValues(),
        ...sortBy(
          subMenuItems,
          [(a, b) => compareLocal(a.name, b.name)],
          ['asc']
        ),
      ];
    }
    if (activeSubMenuStateFilterKey !== 'organisationUnits') {
      // We dont want units sorted, because they are shown hierarchy
      return sortBy(
        subMenuItems,
        [(a, b) => compareLocal(a.name, b.name)],
        ['asc']
      );
    }
    return subMenuItems;
  };

  getMentorDefaultFilterValues = () => {
    const { filters } = this.state;
    return [
      {
        selected: !filters.mentors.deselected.includes(EMPTY_ID),
        name: localeLookup('translations.(Empty) - Not in training'),
        id: EMPTY_ID,
        onToggle: () =>
          this.onToggleFilter({
            type: 'mentors',
            id: EMPTY_ID,
          }),
      },
      {
        selected: !filters.mentors.deselected.includes(NONE_ID),
        name: localeLookup('translations.No mentor'),
        id: NONE_ID,
        onToggle: () =>
          this.onToggleFilter({
            type: 'mentors',
            id: NONE_ID,
          }),
      },
      {
        selected: !filters.mentors.deselected.includes(DELETED_PERSON_ID),
        name: localeLookup('translations.Deleted person'),
        id: DELETED_PERSON_ID,
        onToggle: () =>
          this.onToggleFilter({
            type: 'mentors',
            id: DELETED_PERSON_ID,
          }),
      },
      {
        selected: !filters.mentors.deselected.includes(DELETED_GROUP_ID),
        name: localeLookup('translations.Deleted group'),
        id: DELETED_GROUP_ID,
        onToggle: () =>
          this.onToggleFilter({
            type: 'mentors',
            id: DELETED_GROUP_ID,
          }),
      },
    ];
  };

  getMentorGroupFilterValues = () => {
    const { filters } = this.state;
    const { groups } = this.props;
    return Object.keys(groups).reduce((acc, id) => {
      if (!groups[id].assignableAs.includes('Mentor')) return acc;
      return [
        ...acc,
        {
          selected: !filters.mentors.deselected.includes(id),
          name: groups[id].name,
          id,
          onToggle: () =>
            this.onToggleFilter({
              type: 'mentors',
              id,
            }),
        },
      ];
    }, []);
  };

  getTrainerDefaultFilterValues = () => {
    const { filters } = this.state;
    const { wildcardPersons } = this.props;
    const wildcardNames = {
      [KNOWLEDGE_OWNER_ID]: localeLookup(
        'translations.Missing knowledge owner'
      ),
      [EXPERT_ID]: localeLookup('translations.Missing expert'),
      [MENTOR_ID]: localeLookup('translations.Missing mentor'),
    };
    return Object.keys(wildcardPersons).reduce(
      (acc, id) => {
        if (
          id === SELF_STUDY_ID ||
          id === DELETED_PERSON_ID ||
          id === DELETED_GROUP_ID ||
          id === CHAMP_ID
        )
          return acc;
        return [
          ...acc,
          {
            id,
            selected: !filters.trainers.deselected.includes(id),
            name: wildcardNames[id],
            onToggle: () => this.onToggleFilter({ type: 'trainers', id }),
          },
        ];
      },
      [
        {
          selected: !filters.trainers.deselected.includes(EMPTY_ID),
          name: localeLookup('translations.No responsible'),
          id: EMPTY_ID,
          onToggle: () =>
            this.onToggleFilter({
              type: 'trainers',
              id: EMPTY_ID,
            }),
        },
        {
          selected: !filters.trainers.deselected.includes(DELETED_PERSON_ID),
          name: localeLookup('translations.Deleted person'),
          id: DELETED_PERSON_ID,
          onToggle: () =>
            this.onToggleFilter({
              type: 'trainers',
              id: DELETED_PERSON_ID,
            }),
        },
      ]
    );
  };

  getTrainerInfo = ({ elementId, personId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personElement = person?.knowledgeElements[elementId];
    const isMultipart = personElement?.pending !== PENDING_STATES.NONE;
    const mediatorName = this.getMediatorName(personId, elementId);
    if (mediatorName) {
      if (isMultipart) {
        return {
          id: personElement.mediator,
          name: mediatorName,
          subtitle: `& ${person.name}`,
          subtitleTooltip: `${person.initials}${
            person.employeeNumber ? ` · ${person.employeeNumber}` : ''
          }`,
          isMultipart,
        };
      }
      return {
        id: personElement.mediator,
        name: mediatorName,
        isMultipart,
      };
    }
    return {};
  };

  isPersonElementMissingResponsible = ({ elementId, personId, areaId }) => {
    const { persons, knowledgeAreas } = this.props;
    const knowledgeArea = knowledgeAreas[areaId];
    const person = persons[personId];
    const personElement = person?.knowledgeElements[elementId];
    const isMediatorSpecificPerson = () => {
      const isMediatorEmpty = personElement.mediator === EMPTY_ID;
      const isMediatorFormerEmployee =
        personElement.mediator === DELETED_PERSON_ID ||
        personElement.mediator === DELETED_GROUP_ID;
      const isMediatorKnowledgeOwner =
        personElement.mediator === KNOWLEDGE_OWNER_ID;
      const isMediatorExpert = personElement.mediator === EXPERT_ID;
      const isMediatorMentor = personElement.mediator === MENTOR_ID;
      const elementAreaHasOwner = knowledgeArea.owner !== EMPTY_ID;
      const elementAreaHasExperts = knowledgeArea.experts.length !== 0;
      if (isMediatorEmpty || isMediatorFormerEmployee) {
        return false;
      }
      if (isMediatorMentor) {
        return (
          this.getPersonElementMentorId({ elementId, personId }) !== EMPTY_ID
        );
      }
      if (isMediatorKnowledgeOwner) {
        return elementAreaHasOwner;
      }
      if (isMediatorExpert) {
        return elementAreaHasExperts;
      }
      return true;
    };
    const isMediatorArchived =
      persons[personElement?.mediator] &&
      !persons[personElement?.mediator]?.isActive;
    const isMediatorMissing = !isMediatorSpecificPerson() || isMediatorArchived;
    const doesElementNeedCompletion =
      personElement.hasValidity ||
      !personElement.status.startsWith(MAIN_STATUS_CATEGORIES.VALID_COMPLETION);
    return isMediatorMissing && doesElementNeedCompletion;
  };

  getUserFilterObject = () => {
    const { filters, sorting } = this.state;
    const {
      organisationUnits,
      roles,
      knowledgeAreas,
      knowledgeElements,
      persons,
      wildcardPersons,
    } = this.props;
    // We want to save selected rather than deselected, therefore we invert the filter selection logic
    return {
      sorting: {
        columnId: sorting.columnId,
        direction: sorting.direction,
      },
      status: filters.status,
      elementConfiguration: filters.elementConfiguration,
      otherFilters: filters.otherFilters,
      organisationUnits:
        filters.organisationUnits.deselected.length > 0
          ? Object.keys(organisationUnits).filter(
              (id) => !filters.organisationUnits.deselected.includes(id)
            )
          : [],
      roles:
        filters.roles.deselected.length > 0
          ? Object.keys(roles).filter(
              (id) => !filters.roles.deselected.includes(id)
            )
          : [],
      knowledgeAreas:
        filters.knowledgeAreas.deselected.length > 0
          ? Object.keys(knowledgeAreas).filter(
              (id) => !filters.knowledgeAreas.deselected.includes(id)
            )
          : [],
      knowledgeElements:
        filters.knowledgeElements.deselected.length > 0
          ? Object.keys(knowledgeElements).filter(
              (id) => !filters.knowledgeElements.deselected.includes(id)
            )
          : [],
      persons:
        filters.persons.deselected.length > 0
          ? Object.keys(persons).filter(
              (id) => !filters.persons.deselected.includes(id)
            )
          : [],
      mediators:
        filters.trainers.deselected.length > 0
          ? [
              EMPTY_ID,
              ...Object.keys(wildcardPersons),
              ...Object.keys(persons),
            ].filter((id) => !filters.trainers.deselected.includes(id))
          : [],
      mentors:
        filters.mentors.deselected.length > 0
          ? [
              EMPTY_ID,
              ...Object.keys(wildcardPersons),
              ...Object.keys(persons),
            ].filter((id) => !filters.mentors.deselected.includes(id))
          : [],
    };
  };

  hasElementHistory = ({ elementId, personId }) => {
    const { persons } = this.props;
    const person = persons[personId];
    const personElement = person.knowledgeElements[elementId];
    return personElement.hasHistory;
  };

  hideFilterCategory = () => {
    this.setState({
      showSubMenu: false,
      filterSearchQuery: '',
    });
  };

  isAllRowsSelected = () => {
    const { rows } = this.state;
    if (rows.length === 0) return false;
    return this.getSelectedRowsCount() === rows.length;
  };

  isAnyFiltersActive = () => {
    const { organisationUnits } = this.props;
    const { filters } = this.state;
    return Object.keys(filters).some((key) => {
      if (key === 'status') {
        return !this.isFilterDefault(key, statusFilterDefaultState);
      }
      if (key === 'elementConfiguration') {
        return !this.isFilterDefault(key, elementConfigurationDefaultState);
      }
      if (key === 'otherFilters') {
        return !this.isFilterDefault(key, otherFiltersDefaultState);
      }
      if (key === 'organisationUnits') {
        const isActive =
          filters[key].deselected.length !==
          Object.keys(organisationUnits).length;
        return isActive;
      }
      return filters[key].deselected.length !== 0;
    });
  };

  isElementVisible = ({
    elementId,
    personElementRoleConnections,
    personId,
    status,
    hasValidity,
  }) => {
    const { filters } = this.state;
    const { knowledgeElements, knowledgeAreas } = this.props;
    const element = knowledgeElements[elementId];

    if (element) {
      const { connections } = element;
      const elementExperts = knowledgeAreas[connections.knowledgeArea].experts;
      const mentorId = this.getPersonElementMentorId({ elementId, personId });
      const isAllElementOrganisationUnitsDeselected = () => {
        if (filters.organisationUnits.deselected.length === 0) return false;
        const personElementOrganisationUnits = this.getElementOrganisationUnits(
          {
            elementId,
            personId,
          }
        );
        return personElementOrganisationUnits.every((id) =>
          filters.organisationUnits.deselected.includes(id)
        );
      };

      const isAllElementPersonRolesDeselected =
        filters.roles.deselected.length > 0
          ? personElementRoleConnections.every((id) =>
              filters.roles.deselected.includes(id)
            )
          : false;

      const isElementKnowledgeAreaDeselected =
        filters.knowledgeAreas.deselected.includes(connections.knowledgeArea);

      const isElementIdDeselected =
        filters.knowledgeElements.deselected.includes(elementId);

      const isElementTrainerDeselected = (() => {
        const trainerObject = this.getTrainerInfo({ elementId, personId });
        const isMediatorDeselected = () => {
          if (trainerObject.id) {
            if (trainerObject.id === EXPERT_ID) {
              if (elementExperts.length === 0) {
                return filters.trainers.deselected.includes(EXPERT_ID);
              }
              return elementExperts.every((id) =>
                filters.trainers.deselected.includes(id)
              );
            }
            return filters.trainers.deselected.includes(trainerObject.id);
          }
          return filters.trainers.deselected.includes(EMPTY_ID);
        };
        if (trainerObject.isMultipart) {
          return (
            isMediatorDeselected() &&
            filters.trainers.deselected.includes(personId)
          );
        }
        return isMediatorDeselected();
      })();

      const isElementMentorDeselected =
        filters.mentors.deselected.includes(mentorId);

      const doesElementMatchStatusSettings = () => {
        if (status === STATUS_CATEGORIES.INVALID_COMPLETION_ONGOING) {
          return filters.status.invalidCompletionOngoing;
        }
        if (status.startsWith(MAIN_STATUS_CATEGORIES.NO_COMPLETION)) {
          return filters.status.noCompletion;
        }
        if (
          status === STATUS_CATEGORIES.VALID_COMPLETION ||
          status === STATUS_CATEGORIES.VALID_COMPLETION_WITH_EXPIRATION
        ) {
          return filters.status.validCompletion;
        }
        if (status === STATUS_CATEGORIES.VALID_COMPLETION_EXPIRING) {
          return filters.status.validCompletionExpiring;
        }
        if (status === STATUS_CATEGORIES.INVALID_COMPLETION_EXPIRED) {
          return filters.status.invalidCompletionExpired;
        }
      };

      const doesElementMatchTypeSettings = () => {
        if (element.isRequired) {
          return filters.elementConfiguration.critical;
        }
        if (!element.isRequired) {
          return filters.elementConfiguration.notCritical;
        }
        return false;
      };

      const doesElementMatchExpirationSettings = () => {
        if (hasValidity) {
          return filters.elementConfiguration.withExpiration;
        }
        if (!hasValidity) {
          return filters.elementConfiguration.withoutExpiration;
        }
        return false;
      };

      const doesElementMatchSignatureSettings = () => {
        if (element.completionRequirement !== 'Signature') {
          return filters.elementConfiguration.doesNotRequireSignature;
        }
        if (element.completionRequirement === 'Signature') {
          return filters.elementConfiguration.requiresSignature;
        }
        return false;
      };

      const doesElementMatchMultipartSettings = () => {
        if (element.completionType === TRAINING_REGISTRATION_TYPES.MULTIPART) {
          return filters.elementConfiguration.multipart;
        }
        if (element.completionType === TRAINING_REGISTRATION_TYPES.STANDARD) {
          return filters.elementConfiguration.notMultipart;
        }
        return false;
      };

      const doesElementMatchConnectionSettings = () => {
        if (personElementRoleConnections.length > 0) {
          return filters.otherFilters.currentlyConnected;
        }
        if (personElementRoleConnections.length === 0) {
          return filters.otherFilters.notConnected;
        }
        return false;
      };
      if (
        isAllElementOrganisationUnitsDeselected() ||
        isElementKnowledgeAreaDeselected ||
        isElementIdDeselected ||
        isAllElementPersonRolesDeselected ||
        isElementTrainerDeselected ||
        isElementMentorDeselected
      ) {
        return false;
      }
      return (
        doesElementMatchStatusSettings() &&
        doesElementMatchTypeSettings() &&
        doesElementMatchExpirationSettings() &&
        doesElementMatchSignatureSettings() &&
        doesElementMatchMultipartSettings() &&
        doesElementMatchConnectionSettings()
      );
    }
    return false;
  };

  getRowHighlightColor = (personId, elementId) => {
    const { knowledgeElements, persons } = this.props;
    const element = knowledgeElements[elementId];
    const person = persons[personId];
    const personElement = person.knowledgeElements[elementId];
    if (element.isRequired) {
      if (
        personElement.status.startsWith(MAIN_STATUS_CATEGORIES.VALID_COMPLETION)
      ) {
        return 'grey';
      }
      return 'red';
    }
    return '';
  };

  isRowSelected = (personId, elementId) => {
    const { selectedRows } = this.state;
    if (!selectedRows[personId]) return false;
    const isSelected = selectedRows[personId].includes(elementId);

    return isSelected;
  };

  isFilterDefault = (stateKey, defaultState) => {
    const { filters } = this.state;
    return Object.keys(filters[stateKey]).every(
      (key) => filters[stateKey][key] === defaultState[key]
    );
  };

  onBulkActionSubmit = ({ action, mediator, note, validFromDate }) => {
    const count = this.getSelectedRowsCount();
    const subtitle = `${
      count === 1
        ? localeLookup('translations.{0} selected element', [count])
        : localeLookup('translations.{0} selected elements', [count])
    }`;
    const body = localeLookup(
      'translations.This change cannot be undone as a bulk action, but can be changed on the individual elements afterwards'
    );
    if (action === 'complete') {
      this.showModal({
        modalType: 'confirmation',
        modalProps: {
          title: localeLookup('translations.Complete'),
          subtitle,
          infoText: localeLookup(
            'translations.This action adds a completion to the selected elements which you have permission to complete'
          ),
          body,
          safeWord: count >= 10 && localeLookup('translations.Confirm'),
          confirmButtonText: localeLookup('translations.Complete'),
          confirmButtonType: 'alert',
          fullWidth: true,
          maxWidth: '500px',
          onConfirm: this.completeSelectedElements,
        },
      });
    } else if (action === 'adminApproval') {
      this.showModal({
        modalType: 'confirmation',
        modalProps: {
          title: localeLookup('translations.Complete as admin'),
          subtitle,
          infoText: localeLookup(
            "translations.This action overrides the elements' completion requirements and adds an admin completion to the selected elements"
          ),
          body,
          safeWord: count >= 10 && localeLookup('translations.Confirm'),
          confirmButtonText: localeLookup('translations.Complete'),
          confirmButtonType: 'alert',
          fullWidth: true,
          maxWidth: '500px',
          onConfirm: () =>
            this.addAdminApprovalToSelectedElements({ note, validFromDate }),
        },
      });
    } else if (action === 'resetStatus') {
      this.showModal({
        modalType: 'confirmation',
        modalProps: {
          title: localeLookup('translations.Add reset'),
          subtitle,
          infoText: localeLookup(
            "translations.This action adds a reset to the selected elements which you have permission to reset. The elements' history will be preserved"
          ),
          body,
          safeWord: count >= 10 && localeLookup('translations.Confirm'),
          confirmButtonText: localeLookup('translations.Add'),
          confirmButtonType: 'alert',
          fullWidth: true,
          maxWidth: '500px',
          onConfirm: () => this.addStatusResetToSelectedElements({ note }),
        },
      });
    } else if (action === 'adminResetStatus') {
      this.showModal({
        modalType: 'confirmation',
        modalProps: {
          title: localeLookup('translations.Add reset as admin'),
          subtitle,
          infoText: localeLookup(
            "translations.This action ignores if the elements are locked and adds an admin reset to the selected elements. The elements' history will be preserved"
          ),
          body,
          safeWord: count >= 10 && localeLookup('translations.Confirm'),
          confirmButtonText: localeLookup('translations.Add'),
          confirmButtonType: 'alert',
          fullWidth: true,
          maxWidth: '500px',
          onConfirm: () =>
            this.addAdminStatusResetToSelectedElements({ note, validFromDate }),
        },
      });
    } else if (action === 'mediator') {
      this.showModal({
        modalType: 'confirmation',
        modalProps: {
          title: localeLookup('translations.Change responsible'),
          subtitle,
          infoText: localeLookup(
            'translations.This action will change the responsible for the selected elements'
          ),
          body,
          safeWord: count >= 10 && localeLookup('translations.Confirm'),
          confirmButtonText: localeLookup('translations.Change'),
          confirmButtonType: 'alert',
          fullWidth: true,
          maxWidth: '500px',
          onConfirm: () => this.changeSelectedElementsMediator(mediator),
        },
      });
    }
  };

  onChangeSortingClick = (sortColumnId) => {
    const { sorting } = this.state;
    this.setState(
      {
        sorting: {
          ...sorting,
          columnId: sortColumnId,
          direction:
            sortColumnId === sorting.columnId && sorting.direction === 'asc'
              ? 'desc'
              : 'asc',
        },
      },
      this.buildRows
    );
  };

  onCloseModal = () => {
    const { modal } = this.state;
    this.setState({
      modal: {
        ...modal,
        activeModal: '',
      },
    });
  };

  onFilterCategoryDeselectAll = () => {
    const { filters, activeSubMenuStateFilterKey } = this.state;
    this.setState(
      {
        filters: {
          ...filters,
          [activeSubMenuStateFilterKey]: {
            ...filters[activeSubMenuStateFilterKey],
            deselected: this.getSubMenuItems().map((item) => item.id),
          },
        },
      },
      () => {
        this.deselectAllRows();
        this.buildRows();
      }
    );
  };

  onFilterCategoryPress = (propKey, stateFilterKey, name) => {
    this.setState(
      {
        showSubMenu: true,
        activeSubMenuProp: propKey,
        activeSubMenuStateFilterKey: stateFilterKey,
        subMenuTitle: name,
      },
      () => {
        this.scrollToSubmenuTop();
        if (this.filterInput) {
          this.filterInput.focus();
        }
      }
    );
  };

  onFilterCategorySelectAll = () => {
    const { filters, activeSubMenuStateFilterKey } = this.state;
    this.setState(
      {
        filters: {
          ...filters,
          [activeSubMenuStateFilterKey]: {
            ...filters[activeSubMenuStateFilterKey],
            deselected: [],
          },
        },
      },
      () => {
        this.deselectAllRows();
        this.buildRows();
      }
    );
  };

  onFilterSearchChange = (e) => {
    this.setState({
      filterSearchQuery: e.target.value,
    });
  };

  onResetFilterClick = () => {
    const { organisationUnits } = this.props;
    const { filters } = this.state;
    this.setState(
      {
        filters: Object.keys(filters).reduce(
          (acc, key) => {
            if (key === 'organisationUnits') {
              return {
                ...acc,
                [key]: {
                  ...filters[key],
                  deselected: Object.keys(organisationUnits),
                },
              };
            }
            if (acc[key]) {
              return acc;
            }
            return {
              ...acc,
              [key]: {
                ...filters[key],
                deselected: [],
              },
            };
          },
          {
            status: statusFilterDefaultState,
            elementConfiguration: elementConfigurationDefaultState,
            otherFilters: otherFiltersDefaultState,
          }
        ),
      },
      () => {
        this.deselectAllRows();
        this.buildRows();
      }
    );
  };

  onResetSingleFilterClick = (stateKey, defaultState) => {
    const { filters } = this.state;
    if (defaultState) {
      this.setState(
        {
          filters: {
            ...filters,
            [stateKey]: defaultState,
          },
        },
        () => {
          this.deselectAllRows();
          this.buildRows();
        }
      );
    } else {
      this.setState(
        {
          filters: {
            ...filters,
            [stateKey]: {
              ...filters[stateKey],
              deselected: [],
            },
          },
        },
        () => {
          this.deselectAllRows();
          this.buildRows();
        }
      );
    }
  };

  onSelectAllRows = (e) => {
    const { rows } = this.state;
    const selectAll = e.target.checked;
    if (selectAll) {
      this.setState({
        selectedRows: rows.reduce((acc, row) => {
          if (acc[row.data.personId]) {
            return {
              ...acc,
              [row.data.personId]: [
                ...acc[row.data.personId],
                row.data.elementId,
              ],
            };
          }
          return {
            ...acc,
            [row.data.personId]: [row.data.elementId],
          };
        }, {}),
      });
    } else {
      this.deselectAllRows();
    }
  };

  onSelectRow = (personId, elementId) => {
    const { selectedRows } = this.state;
    if (selectedRows[personId]) {
      if (selectedRows[personId].includes(elementId)) {
        this.setState({
          selectedRows: {
            ...selectedRows,
            [personId]: selectedRows[personId].filter((id) => id !== elementId),
          },
        });
      } else {
        this.setState({
          selectedRows: {
            ...selectedRows,
            [personId]: [...selectedRows[personId], elementId],
          },
        });
      }
    } else {
      this.setState({
        selectedRows: {
          ...selectedRows,
          [personId]: [elementId],
        },
      });
    }
  };

  onShowDescriptionClick = ({ name, description, files }) => {
    this.showModal({
      modalType: 'richText',
      modalProps: {
        title: name,
        description,
        files,
        fullWidth: true,
        maxWidth: '700px',
      },
    });
  };

  onToggleFilter = ({ type, id, callback }) => {
    const { filters } = this.state;
    const filter = filters[type];
    if (filter.deselected.includes(id)) {
      this.setState(
        {
          filters: {
            ...filters,
            [type]: {
              ...filters[type],
              deselected: filter.deselected.filter(
                (deselectedId) => deselectedId !== id
              ),
            },
          },
        },
        async () => {
          await callback?.();
          this.deselectAllRows();
          this.buildRows();
        }
      );
    } else {
      this.setState(
        {
          filters: {
            ...filters,
            [type]: {
              ...filters[type],
              deselected: [...filter.deselected, id],
            },
          },
        },
        async () => {
          await callback?.();
          this.deselectAllRows();
          this.buildRows();
        }
      );
    }
  };
  onToggleMultipleFilters = ({ type, ids, callback }) => {
    const { filters } = this.state;
    const filter = filters[type];
    const isAllSelected = ids.every((id) => !filter.deselected.includes(id));
    if (!isAllSelected) {
      this.setState(
        {
          filters: {
            ...filters,
            [type]: {
              ...filters[type],
              deselected: filter.deselected.filter(
                (deselectedId) => !ids.includes(deselectedId)
              ),
            },
          },
        },
        async () => {
          await callback?.();
          this.deselectAllRows();
          this.buildRows();
        }
      );
    } else {
      this.setState(
        {
          filters: {
            ...filters,
            [type]: {
              ...filters[type],
              deselected: [...filter.deselected, ...ids],
            },
          },
        },
        async () => {
          await callback?.();
          this.deselectAllRows();
          this.buildRows();
        }
      );
    }
  };

  onToggleStaticFilter = (filterStateKey, stateKey) => {
    const { filters } = this.state;
    this.setState(
      {
        filters: {
          ...filters,
          [filterStateKey]: {
            ...filters[filterStateKey],
            [stateKey]: !filters[filterStateKey][stateKey],
          },
        },
      },
      () => {
        this.deselectAllRows();
        this.buildRows();
      }
    );
  };

  onUserFilterCreateClick = () => {
    this.showModal({
      modalType: 'nameModal',
      modalProps: {
        title: localeLookup('translations.Save current filter'),
        confirmButtonText: localeLookup('translations.Save'),
        inputPlaceholder: localeLookup('translations.Name'),
        onConfirm: ({ name }) => {
          this.onCloseModal();
          this.createUserFilter(name);
        },
      },
    });
  };

  onUserFilterDeleteClick = (filter) => {
    const { deleteUserFilter } = this.props;
    this.showModal({
      modalType: 'confirmation',
      modalProps: {
        title: localeLookup('translations.Are you sure you want to delete'),
        subtitle: `${filter.name}?`,
        confirmButtonText: localeLookup('translations.Delete'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          deleteUserFilter(filter.id);
        },
      },
    });
  };

  onUserFilterLoadClick = async (filter) => {
    const { filters, sorting } = this.state;
    const {
      organisationUnits,
      roles,
      knowledgeAreas,
      knowledgeElements,
      persons,
      wildcardPersons,
    } = this.props;

    // We want to save selected rather than deselected, therefore we invert the filter selection logic
    this.setState(
      {
        isLoading: true,
        sorting: filter.sorting
          ? {
              ...sorting,
              columnId:
                filter.sorting?.columnId === ''
                  ? COLUMN_IDS.ELEMENT_NAME
                  : filter.sorting?.columnId,
              direction:
                filter.sorting?.direction === ''
                  ? 'asc'
                  : filter.sorting?.direction,
            }
          : { columnId: COLUMN_IDS.ELEMENT_NAME, direction: 'asc' },
        filters: {
          ...filters,
          status: {
            ...filters.status,
            ...filter.status,
          },
          elementConfiguration: {
            ...filters.elementConfiguration,
            ...filter.elementConfiguration,
          },
          otherFilters: {
            ...filters.otherFilters,
            ...filter.otherFilters,
          },
          organisationUnits: {
            ...filters.organisationUnits,
            deselected:
              filter.organisationUnits && filter.organisationUnits?.length !== 0
                ? Object.keys(organisationUnits).filter(
                    (id) => !filter.organisationUnits?.includes(id)
                  )
                : [],
          },
          roles: {
            ...filters.roles,
            deselected:
              filter.roles && filter.roles?.length !== 0
                ? Object.keys(roles).filter((id) => !filter.roles?.includes(id))
                : [],
          },
          knowledgeAreas: {
            ...filters.knowledgeAreas,
            deselected:
              filter.knowledgeAreas && filter.knowledgeAreas?.length !== 0
                ? Object.keys(knowledgeAreas).filter(
                    (id) => !filter.knowledgeAreas?.includes(id)
                  )
                : [],
          },
          knowledgeElements: {
            ...filters.knowledgeElements,
            deselected:
              filter.knowledgeElements && filter.knowledgeElements?.length !== 0
                ? Object.keys(knowledgeElements).filter(
                    (id) => !filter.knowledgeElements?.includes(id)
                  )
                : [],
          },
          persons: {
            ...filters.persons,
            deselected:
              filter.persons && filter.persons?.length !== 0
                ? Object.keys(persons).filter(
                    (id) => !filter.persons?.includes(id)
                  )
                : [],
          },
          trainers: {
            ...filters.trainers,
            deselected:
              filter.mediators && filter.mediators?.length !== 0
                ? [
                    EMPTY_ID,
                    ...Object.keys(wildcardPersons),
                    ...Object.keys(persons),
                  ].filter((id) => !filter.mediators?.includes(id))
                : [],
          },
          mentors: {
            ...filters.mentors,
            deselected:
              filter.mentors && filter.mentors?.length !== 0
                ? [
                    EMPTY_ID,
                    ...Object.keys(wildcardPersons),
                    ...Object.keys(persons),
                  ].filter((id) => !filter.mentors?.includes(id))
                : [],
          },
        },
      },
      async () => {
        await this.getOrganisationUnitsData();
        this.deselectAllRows();
        this.buildRows();
      }
    );
  };

  onUserFilterRenameClick = (filter) => {
    const { updateUserFilter } = this.props;
    this.showModal({
      modalType: 'nameModal',
      modalProps: {
        title: localeLookup('translations.Rename'),
        inputPlaceholder: filter.name,
        confirmButtonText: localeLookup('translations.Rename'),
        onConfirm: ({ name }) => {
          this.onCloseModal();
          updateUserFilter(filter.id, { ...filter, name });
        },
      },
    });
  };

  onUserFilterUpdateClick = (filter) => {
    const { updateUserFilter } = this.props;
    this.showModal({
      modalType: 'confirmation',
      modalProps: {
        title: localeLookup('translations.Are you sure you want to overwrite'),
        subtitle: `${filter.name}?`,
        body: localeLookup(
          'translations.This will overwrite this saved filter with the current filter'
        ),
        confirmButtonText: localeLookup('translations.Overwrite'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          updateUserFilter(filter.id, {
            ...this.getUserFilterObject(),
            name: filter.name,
          });
        },
      },
    });
  };

  scrollToSubmenuTop = () => {
    this.submenuList && this.submenuList.scrollToPosition(0);
  };

  showModal = ({ modalType, modalProps }) => {
    this.setState({
      modal: {
        activeModal: modalType,
        ...modalProps,
      },
    });
  };

  updatePerson = (personId) => {
    const { getLimitedPersonsElementStatus } = this.props;
    return getLimitedPersonsElementStatus([personId]);
  };

  renderHeaderCell = ({ columnIndex, key, style }) => {
    const { sorting } = this.state;
    const row = this.headerCells;
    const cell = row[columnIndex];
    if (cell.type === 'checkbox') {
      const onChange = this.onSelectAllRows;
      const isChecked = this.isAllRowsSelected();
      return (
        <TableHeaderCell key={key} style={style} centerContent>
          <Checkbox
            id="headerSelectAll"
            onChange={onChange}
            isChecked={isChecked}
          />
        </TableHeaderCell>
      );
    }
    return (
      <TableHeaderCell
        sortable={cell.sortable}
        sortActive={sorting.columnId === cell.columnId}
        sortDirection={sorting.direction}
        onClick={
          cell.sortable ? () => this.onChangeSortingClick(cell.columnId) : null
        }
        icon={cell.icon}
        key={key}
        title={cell.title}
        style={style}
      />
    );
  };

  renderModal = () => {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal.activeModal}
        onCloseClick={this.onCloseModal}
        maxWidth={modal.maxWidth || '700px'}
        fullWidth={modal.fullWidth}
        render={({ onCloseClick }) => (
          <>
            {modal.activeModal === 'elementHistory' && (
              <GenericModal
                deep
                cancelButtonText={localeLookup('translations.Close')}
                onCancelClick={onCloseClick}
                onClose={onCloseClick}
                title={localeLookup('translations.History')}
              >
                <ElementHistoryList
                  elementId={modal.elementId}
                  personId={modal.personId}
                  onChangeHistory={() => this.updatePerson(modal.personId)}
                />
              </GenericModal>
            )}
            {modal.activeModal === 'confirmation' && (
              <ConfirmationModal
                title={modal.title}
                subtitle={modal.subtitle}
                safeWord={modal.safeWord}
                body={modal.body}
                btnConfirmTitle={modal.confirmButtonText}
                onCancel={onCloseClick}
                onClose={onCloseClick}
                btnConfirmKind={modal.confirmButtonType}
                onConfirm={modal.onConfirm}
                infoText={modal.infoText}
              />
            )}
            {modal.activeModal === 'information' && (
              <InfoModal
                title={modal.title}
                subtitle={modal.subtitle}
                body={modal.body}
                btnConfirmTitle={modal.confirmButtonText}
                onCancel={onCloseClick}
                onClose={onCloseClick}
                btnConfirmKind={modal.confirmButtonType}
                onConfirm={modal.onConfirm}
              />
            )}
            {modal.activeModal === 'richText' && (
              <RichTextModal
                title={modal.title}
                subtitle={modal.subtitle}
                body={modal.description}
                files={modal.files}
                onClose={onCloseClick}
              />
            )}

            {modal.activeModal === 'nameModal' && (
              <FormWrapper
                onSubmit={modal.onConfirm}
                initialValues={{ name: '' }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required(
                    localeLookup('translations.Name is required')
                  ),
                })}
              >
                {(props) => {
                  const {
                    errors,
                    handleSubmit,
                    values,
                    dirty,
                    handleChange,
                    isValid,
                  } = props;
                  return (
                    <GenericModal
                      confirmButtonText={modal.confirmButtonText}
                      onCancelClick={onCloseClick}
                      onClose={onCloseClick}
                      onConfirmClick={handleSubmit}
                      title={modal.title}
                      confirmDisabled={!isValid || !dirty}
                    >
                      <form onSubmit={handleSubmit}>
                        <Input
                          autoFocus
                          fullWidth
                          placeholder={modal.inputPlaceholder}
                          id="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <ErrorMessage show={errors.name}>
                          {errors.name}
                        </ErrorMessage>
                      </form>
                    </GenericModal>
                  );
                }}
              </FormWrapper>
            )}
          </>
        )}
      />
    );
  };

  renderElementConfigurationSubmenu = () => {
    const { filters } = this.state;
    return (
      <ElementStatusElementConfigurationFilter
        isDefault={this.isFilterDefault(
          'elementConfiguration',
          elementConfigurationDefaultState
        )}
        onClickReset={() =>
          this.onResetSingleFilterClick(
            'elementConfiguration',
            elementConfigurationDefaultState
          )
        }
        onToggleFilter={(stateKey) =>
          this.onToggleStaticFilter('elementConfiguration', stateKey)
        }
        filterState={filters.elementConfiguration}
      />
    );
  };

  renderOrganisationUnitsSubmenu = () => {
    const { organisationUnitRootNodes } = this.props;
    const { filters, filterSearchQuery, isLoading } = this.state;
    const submenuItems = this.getFilteredSubMenuItems();
    return (
      <ElementStatusOrganisationUnitFilter
        isLoading={isLoading}
        isDefault={this.isFilterDefault(
          'otherFilters',
          otherFiltersDefaultState
        )}
        onClickReset={() =>
          this.onResetSingleFilterClick(
            'otherFilters',
            otherFiltersDefaultState
          )
        }
        organisationUnitRootNodes={organisationUnitRootNodes}
        onToggleMultipleFilters={(ids) => {
          const filter = filters.organisationUnits;
          const isAllSelected = ids.every(
            (id) => !filter.deselected.includes(id)
          );
          if (!isAllSelected) {
            this.checkForLargeDataAmount({
              organisationUnits: ids,
              onConfirm: () =>
                this.onToggleMultipleFilters({
                  type: 'organisationUnits',
                  ids,
                  callback: () => this.getOrganisationUnitsData(ids),
                }),
            });
          } else {
            this.onToggleMultipleFilters({ type: 'organisationUnits', ids });
          }
        }}
        onToggleFilter={(id) => {
          const filter = filters.organisationUnits;
          if (filter.deselected.includes(id)) {
            this.checkForLargeDataAmount({
              organisationUnits: [id],
              onConfirm: () =>
                this.onToggleFilter({
                  type: 'organisationUnits',
                  id,
                  callback: () => this.getOrganisationUnitsData([id]),
                }),
            });
          } else {
            this.onToggleFilter({ type: 'organisationUnits', id });
          }
        }}
        filterState={filters.organisationUnits}
        items={submenuItems}
        organisationUnits={this.props.organisationUnits}
        filterSearchQuery={filterSearchQuery}
        onFilterSearchChange={this.onFilterSearchChange}
      />
    );
  };

  renderOtherFiltersSubmenu = () => {
    const { filters } = this.state;
    return (
      <ElementStatusOtherFiltersFilter
        isDefault={this.isFilterDefault(
          'otherFilters',
          otherFiltersDefaultState
        )}
        onClickReset={() =>
          this.onResetSingleFilterClick(
            'otherFilters',
            otherFiltersDefaultState
          )
        }
        onToggleFilter={(stateKey) =>
          this.onToggleStaticFilter('otherFilters', stateKey)
        }
        filterState={filters.otherFilters}
      />
    );
  };

  renderStatusSubmenu = () => {
    const { filters } = this.state;
    return (
      <ElementStatusStatusFilter
        isDefault={this.isFilterDefault('status', statusFilterDefaultState)}
        onClickReset={() =>
          this.onResetSingleFilterClick('status', statusFilterDefaultState)
        }
        onToggleFilter={(stateKey) =>
          this.onToggleStaticFilter('status', stateKey)
        }
        filterState={filters.status}
      />
    );
  };

  renderSubmenu = () => {
    const {
      showSubMenu,
      subMenuTitle,
      filterSearchQuery,
      activeSubMenuProp,
      organisationUnits,
    } = this.state;
    const submenuItems = this.getFilteredSubMenuItems();
    if (subMenuTitle === 'Status') {
      return (
        <AnimatedAside
          variants={submenuVariants}
          initial="hidden"
          animate={showSubMenu ? 'visible' : 'hidden'}
          isSublevel
          footerComponent={
            <Button fullWidth kind="shy" onClick={this.hideFilterCategory}>
              {localeLookup('translations.Back')}
            </Button>
          }
        >
          {this.renderStatusSubmenu()}
        </AnimatedAside>
      );
    }
    if (subMenuTitle === 'Element configuration') {
      return (
        <AnimatedAside
          variants={submenuVariants}
          initial="hidden"
          animate={showSubMenu ? 'visible' : 'hidden'}
          isSublevel
          footerComponent={
            <Button fullWidth kind="shy" onClick={this.hideFilterCategory}>
              {localeLookup('translations.Back')}
            </Button>
          }
        >
          {this.renderElementConfigurationSubmenu()}
        </AnimatedAside>
      );
    }
    if (subMenuTitle === 'Other filters') {
      return (
        <AnimatedAside
          variants={submenuVariants}
          initial="hidden"
          animate={showSubMenu ? 'visible' : 'hidden'}
          isSublevel
          footerComponent={
            <Button fullWidth kind="shy" onClick={this.hideFilterCategory}>
              {localeLookup('translations.Back')}
            </Button>
          }
        >
          {this.renderOtherFiltersSubmenu()}
        </AnimatedAside>
      );
    }
    if (activeSubMenuProp === 'organisationUnits') {
      return (
        <AnimatedAside
          variants={submenuVariants}
          initial="hidden"
          animate={showSubMenu ? 'visible' : 'hidden'}
          isSublevel
          footerComponent={
            <Button fullWidth kind="shy" onClick={this.hideFilterCategory}>
              {localeLookup('translations.Back')}
            </Button>
          }
        >
          {this.renderOrganisationUnitsSubmenu()}
        </AnimatedAside>
      );
    }
    return (
      <AnimatedAside
        variants={submenuVariants}
        initial="hidden"
        animate={showSubMenu ? 'visible' : 'hidden'}
        isSublevel
        noScroll
        footerComponent={
          <Button fullWidth kind="shy" onClick={this.hideFilterCategory}>
            {localeLookup('translations.Back')}
          </Button>
        }
      >
        <SideNavTitle title={subMenuTitle} />
        <SideNavFilter
          autoFocus
          inputRef={(el) => {
            this.filterInput = el;
          }}
          value={filterSearchQuery}
          onChange={this.onFilterSearchChange}
          placeholder={`${localeLookup('translations.Search')}...`}
          filterActions={[
            {
              title: localeLookup('translations.Select all'),
              onClick: this.onFilterCategorySelectAll,
            },
            {
              title: localeLookup('translations.Deselect all'),
              onClick: this.onFilterCategoryDeselectAll,
            },
          ]}
        />
        <SideNavVirtualListWrapper>
          <AutoSizer nonce={cspNonce}>
            {({ height }) => (
              <List
                ref={(el) => (this.submenuList = el)}
                height={height}
                rowHeight={50}
                rowRenderer={({ index, key, style }) => {
                  const item = submenuItems[index];
                  return (
                    <SideNavItem
                      small
                      element="div"
                      showHoverTitle
                      key={key}
                      style={style}
                      title={item.name}
                      tooltip={item.tooltip}
                      subtitle={item.subtitle}
                      onClick={item.onToggle}
                      stopRightComponentClickPropagation
                      rightComponent={
                        <Checkbox
                          onChange={item.onToggle}
                          isChecked={item.selected}
                          id={key}
                        />
                      }
                    />
                  );
                }}
                rowCount={submenuItems.length}
                width={279}
              />
            )}
          </AutoSizer>
        </SideNavVirtualListWrapper>
      </AnimatedAside>
    );
  };

  renderSidebar = () => {
    const { presetFilters, organisationUnits } = this.props;
    const dynamicFilterItems = [
      {
        name: localeLookup('translations.Organisation units'),
        propKey: 'organisationUnits',
        stateFilterKey: 'organisationUnits',
      },
      {
        name: localeLookup('translations.Roles'),
        propKey: 'roles',
        stateFilterKey: 'roles',
      },
      {
        name: localeLookup('translations.Knowledge areas'),
        propKey: 'knowledgeAreas',
        stateFilterKey: 'knowledgeAreas',
      },
      {
        name: localeLookup('translations.Elements'),
        propKey: 'knowledgeElements',
        stateFilterKey: 'knowledgeElements',
      },
      {
        name: localeLookup('translations.Persons'),
        propKey: 'persons',
        stateFilterKey: 'persons',
      },
      {
        name: localeLookup('translations.Responsible'),
        propKey: 'persons',
        stateFilterKey: 'trainers',
      },
      {
        name: localeLookup('translations.Mentor'),
        propKey: 'persons',
        stateFilterKey: 'mentors',
      },
    ];

    const sortedFilterPresetIds = sortBy(
      Object.keys(presetFilters),
      [(a, b) => compareLocal(presetFilters[a].name, presetFilters[b].name)],
      ['asc']
    );

    return (
      <Aside
        className="status-table__sidebar"
        footerComponent={
          <Button
            disabled={!this.isAnyFiltersActive()}
            fullWidth
            kind="alert"
            onClick={this.onResetFilterClick}
          >
            {localeLookup('translations.Reset filters')}
          </Button>
        }
      >
        <>
          <SideNavTitle
            title={localeLookup('translations.Saved filters')}
            icon="plus-circle"
            iconColor="green"
            onIconClick={this.onUserFilterCreateClick}
          />
          {Object.keys(presetFilters).length === 0 && (
            <SideNavText>
              {localeLookup('translations.No saved filters')}
            </SideNavText>
          )}
          {sortedFilterPresetIds.map((id) => {
            const filter = presetFilters[id];
            const isAllFilterOrganisationUnitsAccessible =
              filter.organisationUnits.every((unitId) =>
                Object.keys(organisationUnits).includes(unitId)
              );
            return (
              <SlideButton
                actions={this.filterPresetActions.map((action) => ({
                  ...action,
                  onClick: () => {
                    action.onClick(filter);
                  },
                }))}
                key={`presetFilter${id}`}
                renderButton={() => (
                  <SideNavItem
                    element="div"
                    onClick={() => this.onUserFilterLoadClick(filter)}
                    title={filter.name}
                    noTruncate
                    rightComponent={
                      isAllFilterOrganisationUnitsAccessible ? null : (
                        <Icon
                          kind="warning"
                          color="red"
                          tooltip={localeLookup(
                            'translations.Some organisation units in this filter are no longer accessible'
                          )}
                        />
                      )
                    }
                  />
                )}
                showTrigger
              />
            );
          })}
          <SideNavListSection title={localeLookup('translations.Filter')}>
            <SideNavItem
              title={localeLookup('translations.Status')}
              onClick={() => {
                this.onFilterCategoryPress(null, null, 'Status');
              }}
              rightComponent={
                this.isFilterDefault(
                  'status',
                  statusFilterDefaultState
                ) ? null : (
                  <Icon color="blue" kind="settings" />
                )
              }
            />
            <SideNavItem
              title={localeLookup('translations.Element configuration')}
              onClick={() => {
                this.onFilterCategoryPress(null, null, 'Element configuration');
              }}
              rightComponent={
                this.isFilterDefault(
                  'elementConfiguration',
                  elementConfigurationDefaultState
                ) ? null : (
                  <Icon color="blue" kind="settings" />
                )
              }
            />
            {dynamicFilterItems.map((item) => {
              const selectionCount = this.getFilterSelectionCount(
                item.propKey,
                item.stateFilterKey
              );
              if (item.stateFilterKey === 'organisationUnits') {
                return (
                  <SideNavItem
                    key={item.name}
                    title={item.name}
                    onClick={() => {
                      this.onFilterCategoryPress(
                        item.propKey,
                        item.stateFilterKey,
                        item.name
                      );
                    }}
                    stopRightComponentClickPropagation
                    rightComponent={
                      <NotificationCounter
                        color={selectionCount === 0 ? 'red' : 'grey'}
                        size="small"
                        count={`${selectionCount} / ${
                          Object.keys(organisationUnits).length
                        }`}
                      />
                    }
                  />
                );
              }
              return (
                <SideNavItem
                  key={item.name}
                  title={item.name}
                  onClick={() => {
                    this.onFilterCategoryPress(
                      item.propKey,
                      item.stateFilterKey,
                      item.name
                    );
                  }}
                  stopRightComponentClickPropagation
                  rightComponent={
                    selectionCount === null ? null : (
                      <NotificationCounter
                        onClick={() =>
                          this.onResetSingleFilterClick(item.stateFilterKey)
                        }
                        color="grey"
                        icon="cross2"
                        size="small"
                        count={selectionCount}
                      />
                    )
                  }
                />
              );
            })}
            <SideNavItem
              title={localeLookup('translations.Other filters')}
              onClick={() => {
                this.onFilterCategoryPress(null, null, 'Other filters');
              }}
              rightComponent={
                this.isFilterDefault(
                  'otherFilters',
                  otherFiltersDefaultState
                ) ? null : (
                  <Icon color="blue" kind="settings" />
                )
              }
            />
          </SideNavListSection>
          {this.renderSubmenu()}
        </>
      </Aside>
    );
  };

  renderTableCell = ({ columnIndex, key, rowIndex, style }) => {
    const { rows } = this.state;
    const isNameColumn = columnIndex === 1;
    const isHeader = rowIndex === 0;
    if (isHeader)
      return this.renderHeaderCell({ columnIndex, key, rowIndex, style });
    const row = rows[rowIndex - 1];
    if (!row) return null;
    const cell = row[columnIndex];
    if (cell.type === 'status') {
      const statusInfo = this.getElementStatusInfo({
        elementId: cell.elementId,
        personId: cell.personId,
      });
      return (
        <TableCell key={key} style={style}>
          <StatusButton
            key={`${cell.personId}${cell.elementId}`}
            elementName={cell.elementName}
            personName={cell.personName}
            areaId={cell.areaId}
            elementId={cell.elementId}
            pending={statusInfo.pending}
            personId={cell.personId}
            onChangeStatus={() => this.updatePerson(cell.personId)}
            status={statusInfo.status}
            nextAction={statusInfo.nextAction}
            daysUntilExpiration={statusInfo.daysUntilExpiration}
            hasValidity={statusInfo.hasValidity}
            size="md"
            mediatorId={statusInfo.mediatorId}
            hasHistory={statusInfo.hasHistory}
          />
        </TableCell>
      );
    }
    if (cell.type === 'checkbox') {
      const onChange = () => this.onSelectRow(cell.personId, cell.elementId);
      const isChecked = this.isRowSelected(cell.personId, cell.elementId);
      return (
        <TableCell key={key} style={style} centerContent>
          <Checkbox
            id={`${cell.personId}${cell.elementId}`}
            onChange={onChange}
            isChecked={isChecked}
          />
        </TableCell>
      );
    }
    if (cell.type === 'dynamic') {
      return (
        <TableCell
          tooltip={cell.getTooltip && cell.getTooltip()}
          key={key}
          title={cell.getTitle()}
          titleColor={cell.getTitleColor?.()}
          subtitle={cell.getSubtitle ? cell.getSubtitle() : cell.subtitle}
          subtitleTooltip={
            cell.getSubtitleTooltip
              ? cell.getSubtitleTooltip()
              : cell.getSubtitleTooltip
          }
          onIconClick={cell.onIconClick}
          icon={cell.getIcon ? cell.getIcon() : cell.icon}
          style={style}
        />
      );
    }
    return (
      <TableCell
        highlightColor={
          isNameColumn &&
          this.getRowHighlightColor(row.data.personId, row.data.elementId)
        }
        onIconClick={cell.onIconClick}
        icon={cell.icon}
        tooltip={cell.tooltip}
        key={key}
        title={cell.title}
        subtitle={cell.subtitle}
        style={style}
        titleColor={cell.titleColor}
      />
    );
  };

  renderTable = () => {
    const { rows, isLoading } = this.state;
    const selectedRows = this.getSelectedRowsCount();
    const getColumnWidth = ({ index }) => {
      const widths = [40, 340, 170, 40, 170, 170, 250, 170, 300, 300];
      return widths[index] || 300;
    };
    return (
      <AutoSizer>
        {({ height, width }) => (
          <MultiGrid
            cellRenderer={this.renderTableCell}
            classNameBottomLeftGrid="status-table__table__bottom-left-grid"
            columnCount={11}
            noContentRenderer={() => {
              if (!isLoading) {
                return (
                  <div className="status-table__table-no-content">
                    <EmptyState
                      //icon="warning"
                      fullHeight
                      title={localeLookup('translations.No elements found')}
                      body={localeLookup(
                        'translations.The selected filters contains no elements'
                      )}
                    />
                  </div>
                );
              }
              return null;
            }}
            columnWidth={getColumnWidth}
            enableFixedColumnScroll
            fixedColumnCount={4}
            fixedRowCount={1}
            getScrollbarSize={() => 12}
            height={selectedRows === 0 ? height : height - 64} // Adjust size when header is visible
            rowCount={rows.length + 1}
            rowHeight={40}
            width={width}
          />
        )}
      </AutoSizer>
    );
  };

  render() {
    const { persons, organisationUnits } = this.props;
    const { isLoading, isLoadingInitial, filters } = this.state;
    const isAllOrganisationUnitsDeselected = Object.keys(
      organisationUnits
    ).every((id) => filters.organisationUnits.deselected.includes(id));
    const selectedRows = this.getSelectedRowsCount();
    if (isLoadingInitial) return <LoadScreen />;
    return (
      <>
        <div className="status-table">
          {this.renderSidebar()}
          <div className="status-table__table">
            {isLoading && <LoadOverlay light />}
            <StatusTableHeader
              onSubmit={this.onBulkActionSubmit}
              persons={persons}
              numSelected={selectedRows}
            />
            {isAllOrganisationUnitsDeselected && !isLoading ? (
              <EmptyState
                icon="warning"
                fullHeight
                title={localeLookup(
                  'translations.No organisation units selected'
                )}
                body={localeLookup(
                  'translations.Please select organisation units in the filter to view data'
                )}
              />
            ) : (
              this.renderTable()
            )}
          </div>
        </div>
        <Portal>{this.renderModal()}</Portal>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPersonLookup(WithModals(ElementStatus)));
